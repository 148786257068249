<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }"
        >考勤情况</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/zuzhi/organizationList/myOrganizationList1',
          query: {
            oid: this.oid,
            ccid: this.ccid,
            actId: this.actId,
            createtime: this.createtime,
            fatherName: this.fatherName,
            curriculumName: this.curriculumName
          }
        }"
        >我的组织详情</el-breadcrumb-item
      >

      <el-breadcrumb-item>课程签到活动</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="right-name">
      <div style="font-size:30px;font-weight:bold;" class="course-title">
        {{ curriculumName }}
      </div>
    </el-card>
    <!-- 表格数据 -->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>签到活动列表</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%; height: auto"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          type="index"
          min-width="100"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="title" label="签到名称" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.title }}</p>
          </template>
        </el-table-column> -->
        <el-table-column prop="caption" label="签到标题" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.title }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="acttype" label="签到名单" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.className }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="acttype" label="签到说明" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.caption }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="signStopType" label="签到方式" align="center">
          <template slot-scope="scope">
            <p>
              {{ scope.row.signStopType == 1 ? '手动控制签到' : '倒计时签到' }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleShow(scope.$index, scope.row)"
              >查看签到环节</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signList',
  data() {
    return {
      oid: '',
      ccid: '',
      actId: '',
      fatherName: '',
      createtime: '',
      curriculumName: '',
      signlist: [],
      signData: [],
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      uid: null,
      // 分页
      total: 0,
      flag: null,
      firstdialogVisible: false,
      //选中的签到活动id数组
      actIdArray: [],
      //对话框中的表格数据
      signData2: [],
      //上传文件对话框
      dialogTableVisible: false,
      //上传文件的header
      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },
      listDataforexcel: {
        listName: '',
        uid: window.sessionStorage.getItem('uid')
      },
      //所有的名单
      listHistory: [],
      //导出的数据
      checkedValue: [0, 1, 2, 3, 4, 5],
      attributeName: ['已签到', '未签到', '未认证', '迟到', '请假', '其他']
    }
  },
  created() {
    var toCode = window.sessionStorage.getItem('toCode')
    console.log('rocode', toCode == 'false')
    if (toCode == 'true') {
      this.$confirm('是否跳转到当前签到活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          window.sessionStorage.setItem('toCode', false)
          var lid = window.sessionStorage.getItem('lid')
          var endTime = window.sessionStorage.getItem('loginEndTime')
          var state = window.sessionStorage.getItem('loginState')
          console.log('打印', lid + ' ' + endTime + ' ' + state)
          this.$router.push({
            path: '/signList/signlinkList/signCode',
            query: { lid: lid, EndTime: endTime, status: state }
          })
        })
        .catch(() => {})
    }

    this.tid = window.sessionStorage.getItem('uid')
    this.uid = window.sessionStorage.getItem('uid')
    window.sessionStorage.setItem('tid', this.tid)
    this.oid = this.$route.query.oid
    this.ccid = this.$route.query.ccid
    this.fatherName = this.$route.query.fatherName
    this.curriculumName = this.$route.query.curriculumName
    // this.ccid = window.sessionStorage.getItem('ccid')
    // this.oid = window.sessionStorage.getItem('soid')
    // this.curriculumName = window.sessionStorage.getItem('curriculumName')

    this.selectList()
  },
  mounted: function() {},
  methods: {
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      // console.log('更改页数', this.formInline.PageNum)
      this.selectList()
    },

    // 查询签到环节
    handleShow(index, row) {
      // window.sessionStorage.setItem('createtime', row.createTime)
      // window.sessionStorage.setItem('actId', row.actId)
      this.$router.push({
        path: '/zuzhi/courseHistoryLink/courseHistoryLink1',
        query: {
          oid: this.oid,
          ccid: this.ccid,
          actId: row.actId,
          createtime: row.createTime,
          fatherName: this.fatherName,
          curriculumName: this.curriculumName
        }
      })
    },
    // 查询签到活动
    async selectList() {
      const { data: res } = await this.$http.get('/cou/findActByccid', {
        params: {
          ccid: this.ccid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.signData = res.data
        this.total = res.total
      }
    }
  }
}
</script>

<style scoped>
/* body {
  padding-right: 0px !important;
  overflow: hidden;
} */
.app-container {
  width: 100%;
  /* height: 100vh;
  padding-bottom: 300px;
  overflow: auto !important; */
}
.input-width {
  width: 203px;
}
.home-breadcrumb {
  position: relative;
  top: -52px;
  left: 29px;
}
.course-title {
  text-align: center;
}
</style>
